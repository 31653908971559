import React, { useState, useEffect } from "react"

export const useIsDesktop = (breakpoint = 767) => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    if (window.innerWidth > breakpoint) {
      setIsDesktop(true)
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth > breakpoint) {
        setIsDesktop(true)
      } else {
        setIsDesktop(false)
      }
    })
  }, [])

  return isDesktop
}
