import React from "react"
import Logo from "../../images/icons/Logo"
import { useIsDesktop } from "../../helpers/hooks"
import scrollTo from "gatsby-plugin-smoothscroll"
import SEO from "../SEO"

const links = [
  { text: "about us", link: "about" },
  { text: "Clients", link: "clients" },
  { text: "partners", link: "partners" },
  { text: "project map", link: "map" },
  { text: "Our Projects", link: "projects" },
  { text: "Contact Us", link: "contact" },
]

const Layout = ({ children }) => {
  const isDesktop = useIsDesktop(1199)

  const handleClick = link => {
    document.getElementById("burgertoggle") &&
      document.getElementById("burgertoggle").click()
    scrollTo(`#${link.link}`)
    return null
  }

  return (
    <>
      <SEO />
      <section className="main-container">
        <header>
          <div className="inner">
            <a className="logo" href="/">
              <Logo />
            </a>
            {isDesktop ? (
              <nav className="links">
                {links.map((link, l) => (
                  <div
                    key={l}
                    onClick={() => handleClick(link)}
                    className="link"
                  >
                    {link.text}
                  </div>
                ))}
                <div className="link">
                  <a href="tel:0-800-305-525">0-800-305-525</a>
                </div>
              </nav>
            ) : (
              <div className="burger-container">
                <input id="burgertoggle" type="checkbox" />
                <span />
                <span />
                <span />
                <div className="burger-menu">
                  {links.map((link, l) => (
                    <div
                      key={l}
                      onClick={() => handleClick(link)}
                      className="link"
                    >
                      {link.text}
                    </div>
                  ))}
                  <div className="link">
                    <a href="tel:0-800-305-525">0-800-305-525</a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </header>
        <main>{children}</main>
      </section>
    </>
  )
}

export default Layout
